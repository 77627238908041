import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ServerList from './components/ServerList';
import ServerDetail from './components/ServerDetail';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<ServerList />} />
        <Route path="/servers/:id" element={<ServerDetail />} />
      </Routes>
    </div>
  );
}

export default App;
