import React, { useEffect, useState } from "react";
import "./ServerList.css"; // Import CSS file for styling

const shuffleArray = (array) => {
  if (array.length <= 1) return array; // No need to shuffle if the array has 0 or 1 element
  const shuffledArray = array.slice(); // Create a copy of the array
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

// Function to sort and shuffle servers
const sortAndShuffleServers = (servers) => {
  const downServers = [];
  const unknownServers = [];
  const upAvailableServers = [];
  const upApprovalServers = [];
  const upUnknownSignupServers = [];
  const upUnavailableServers = [];

  servers.forEach((server) => {
    if (server.code >= 400 && server.code <= 499) {
      unknownServers.push(server);
    } else if (server.status === "Down") {
      downServers.push(server);
    } else if (server.status === "Up") {
      if (server.signup === "Available") {
        upAvailableServers.push(server);
      } else if (server.signup === "Approval") {
        upApprovalServers.push(server);
      } else if (server.signup === "Unknown") {
        upUnknownSignupServers.push(server);
      } else if (server.signup === "Unavailable") {
        upUnavailableServers.push(server);
      } else {
        unknownServers.push(server); // Treat as unknown if signup status is not clearly defined
      }
    }
  });

  // Combine all categories into a single array after shuffling each category
  const sortedAndShuffledServers = [
    ...shuffleArray(downServers),
    ...shuffleArray(unknownServers),
    ...shuffleArray(upAvailableServers),
    ...shuffleArray(upApprovalServers),
    ...shuffleArray(upUnknownSignupServers),
    ...shuffleArray(upUnavailableServers),
  ];

  return sortedAndShuffledServers;
};

const ServerList = () => {
  const [servers, setServers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchServers = async () => {
      try {
        const response = await fetch("/api/servers_full");
        const servers = await response.json();

        // Sort and shuffle the servers
        const sortedServers = sortAndShuffleServers(servers);

        // Set the shuffled and sorted servers array
        setServers(sortedServers);
      } catch (error) {
        console.error("Error fetching server statuses:", error);
      } finally {
        setLoading(false); // Stop loading once the data is fetched
      }
    };

    fetchServers();
  }, []); // The empty array ensures the effect runs only once, without warnings.

  if (loading) {
    return (
      <div>
        <h1>페디버스 서버 확인중</h1>
        <div className="loading-container">
          <div className="spinner"></div>
          <p>로딩중...(최대 20초)</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <h1>페디버스 실시간 서버 상태</h1>
      <div className="server-list">
        {servers.map((server) => {
          const hostname = "https://" + new URL(server.url).hostname;
          let statusClass = "";
          let statusLabel = "";

          if (server.code >= 400 && server.code <= 499) {
            statusClass = "unknown";
            statusLabel = "Unknown";
          } else if (server.status === "Up") {
            statusLabel = "UP";
            if (server.signup === "Available") {
              statusClass = "up";
            } else if (server.signup === "Approval") {
              statusClass = "approval";
            } else if (server.signup === "Unknown") {
              statusClass = "up-unknown";
            } else if (server.signup === "Unavailable") {
              statusClass = "unavailable";
            } else {
              statusClass = "up-unknown"; // Default to "up-unknown" if status is unclear
            }
          } else {
            statusClass = "down";
            statusLabel = "DOWN";
          }

          // Add signup status
          let signupStatusClass = "";
          let signupLabel = "";

          if (server.signup) {
            signupStatusClass = server.signup.toLowerCase();
            signupLabel = `Signup: ${server.signup}`;
          }

          return (
            <a
              key={hostname}
              href={hostname}
              target="_blank"
              rel="noopener noreferrer"
              className={`server-card ${statusClass}`}
            >
              <img src={server.icon} alt={`${server.name} icon`} className="server-icon" />
              <div className="server-info">
                <h2>{server.name}</h2>
                <p>
                  <span className={`status ${statusClass}`}>
                    {statusLabel}
                  </span>{" "}
                  <span className="code">HTTP {server.code}</span>
                  <br />
                  {/* Display signup status */}
                  {signupLabel && (
                    <span className={`signup-status ${signupStatusClass}`}>
                      {signupLabel}
                    </span>
                  )}
                </p>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default ServerList;
