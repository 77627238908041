import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

const ServerDetail = () => {
  const [server, setServer] = useState({});
  const { id } = useParams();

  useEffect(() => {
    const fetchServer = async () => {
      const response = await fetch(`/api/servers/${id}`);
      const server = await response.json();
      setServer(server);
    };

    fetchServer();
  }, [id]);

  if (!Object.keys(server).length) return <div>Loading...</div>;

  return (
    <div>
      <h1>{server.name}</h1>
      <img src={server.icon} alt={`${server.name} icon`} style={{ width: 100, height: 100 }} />
      <p>URL: <a href={server.url} target="_blank" rel="noopener noreferrer">{server.url}</a></p>
      <p>Status: {server.status} (Code: {server.code})</p>
      <p>
        <Link to="/">Go back</Link>
      </p>
    </div>
  );
};

export default ServerDetail;
